import Network from '@/services/network'
import { ref } from 'vue'

export default function handleAccounting() {
    const network = new Network;
    const accountingLoading = ref(false)

    const getBalanceSheet = (query) => {
      return network.api('get', '/accounting/balance-sheet'+ query);
    }

    const getBalanceSheetColumnWise = (query) => {
      return network.api('get', '/accounting/fs-datasheet'+ query);
    }

    const getProfitLoss = (query) => {
      return network.api('get', '/accounting/profit-and-loss'+ query);
    }

    const getReceiptsPayments = (query) => {
        return network.api('get', '/accounting/receipts-payments'+ query);
    }

    return {
        getBalanceSheet,
        getBalanceSheetColumnWise,
        getProfitLoss,
        getReceiptsPayments,
        accountingLoading
    }
}
