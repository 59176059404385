<template>
  <div style="min-height: 200px">
    <div class="table-responsive">
      <table id="trial" class="table table-bordered">
        <thead>
        <tr>
          <th>Accounts Type</th>
          <th>Group level 1</th>
          <th>Group level 2</th>
          <th>Group level 3</th>
          <th>Group level 4</th>
          <th>Accounts Head</th>
          <th class="text-end">Total</th>
          <template v-if="dynamicHeader.length > 0">
            <template v-for="(item, i) in dynamicHeader" :key="i">
              <th class="text-end">{{ item.name }}</th>
            </template>
          </template>
          <th class="text-end">Not Specified</th>
        </tr>
        </thead>

        <tbody v-if="balances.length">
        <tr v-for="(item, i) in balances" :key="i">
          <td>{{ item.account_type }}</td>
          <td>{{ item.account_level_1 }}</td>
          <td>{{ item.account_level_2 }}</td>
          <td>{{ item.account_level_3 }}</td>
          <td>{{ item.account_level_4 }}</td>
          <td>{{ item.account_head_name }}</td>
          <td class="text-end">{{ commaFormat(item.total) }}</td>
          <template v-if="dynamicHeader.length > 0">
            <template v-for="(header, i) in dynamicHeader" :key="i">
              <td class="text-end">
                {{ item.balances.hasOwnProperty(header.id) ? commaFormat(item.balances[header.id]) : 0 }}
              </td>
            </template>
          </template>
          <td class="text-end">{{ commaFormat(item.not_specified_balance) }}</td>
        </tr>
        <tr>
          <td colspan="6" class="text-start">Total</td>
          <td class="text-end">{{ commaFormat(total.total) }}</td>
          <template v-if="dynamicHeader.length > 0">
            <template v-for="(header, i) in dynamicHeader" :key="i">
              <td class="text-end">
                {{ total.balances.hasOwnProperty(header.id) ? commaFormat(total.balances[header.id]) : 0 }}
              </td>
            </template>
          </template>
          <td class="text-end">{{ commaFormat(total.not_specified_balance) }}</td>
        </tr>
        </tbody>
      </table>
      <p v-if="!balances.length" class="mt-5 text-center">No data</p>
    </div>
  </div>
</template>

<script setup>
import figureFormatter       from '@/services/utils/figureFormatter'
import {useRoute, useRouter} from "vue-router";

const props = defineProps({
  balances: Array,
  dynamicHeader: Array,
  total: Array,
})

const router = useRouter()
const route = useRoute()
const {commaFormat} = figureFormatter ()
</script>

<style scoped>
#trial td{
  padding: 2px
}
#trial th{
  padding: 10px 3px;
}
</style>
